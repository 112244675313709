<template>
  <div>
    <b-row>
      <b-col
        v-for="(plan, index) in plants"
        :key="index"
        cols="4"
      >
        <b-card no-body>
          <b-card-header class="p-25 bg-primary">
            <div class="text-left text-white">
              <b>{{ plan.center_name }}</b>
            </div>
            <div
              v-if="$can('manage', 'admin')"
              class=" text-right text-nowrap"
            >
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    color="red"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Sil</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card-header>
          <b-card-sub-title class="pt-1 d-flex justify-content-between border ">
            <div class="text-left ">
              <feather-icon
                icon="MapPinIcon"
                color="green"
                class="ml-50 mr-50"
              />
              <span class="align-middle">{{ plan.country.name }}-{{ plan.states.name }}-{{ plan.cities.name }}</span>
            </div>
            <div class=" text-right">
              <feather-icon
                icon="RssIcon"
                color="green"
                class="ml-50 mr-50"
              />
              <span class="align-middle">{{ plan.checkDate }} {{new Date().toLocaleString()}}</span>
            </div>
          </b-card-sub-title>
          <b-card-body class="p-0">
            <b-row>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      class="spinner-grow"
                      color="green"
                      size="40"
                      icon="ActivityIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      233.05 kW
                    </b-row>
                    <b-row>Anlık Güç</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      class="spinner"
                      color="red"
                      size="40"
                      icon="LoaderIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      % 95
                    </b-row>
                    <b-row>Performans</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      color="orange"
                      size="40"
                      icon="GridIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      1,410 kWh
                    </b-row>
                    <b-row>Günlük Üretilen</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <!--              <div class="d-flex justify-content-start align-items-center">-->
                <!--                <div>-->
                <!--                  <feather-icon color="orange" size="60" icon="ChevronDownIcon"/>-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                  <h4>{{plan.dailyConsumption}} kWh</h4>-->
                <!--                  <span>Günlük Tüketilen</span>-->
                <!--                </div>-->
                <!--              </div>-->
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      class="spinner"
                      color="orange"
                      size="40"
                      icon="ChevronDownIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      {{ plan.dailyConsumption }} kWh
                    </b-row>
                    <b-row>Günlük Tüketilen</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      color="gray"
                      size="40"
                      icon="LayersIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      78.200 mWh
                    </b-row>
                    <b-row>Toplam Üretilen</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      class="spinner"
                      color="gray"
                      size="40"
                      icon="ChevronsDownIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      {{ plan.totalConsumption }} mWh
                    </b-row>
                    <b-row>Toplam Tüketilen</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      color="green"
                      size="40"
                      icon="WifiIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      Aktif
                    </b-row>
                    <b-row>Haberleşme</b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                cols="6"
              >
                <b-row align-h="between">
                  <b-col cols="3">
                    <feather-icon
                      color="green"
                      size="40"
                      icon="PowerIcon"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-row class="font-weight-bold">
                      Aktif
                    </b-row>
                    <b-row>Durum</b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr>
            <!-- User Stats -->
            <div class="d-flex p-0 justify-content-end">
              <b-button
                type="submit"
                variant="secondary"
                size="sm"
                class="m-25"
                @click="$router.push({ name: 'customer-plant-detail', params: { id: plan.id }})"
              >
                <span class="align-middle">Detay <feather-icon icon="ChevronsRightIcon"/></span>
              </b-button>
            </div>
          </b-card-body>
        </b-card> </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol, BDropdown, BDropdownItem, BIcon,
  BNavItem,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BIcon,
    BNavItem,
    BAvatar,
    BCardSubTitle,
    BCardHeader,
    BCardTitle,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
  },
  props: {
    plants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
</style>
