<template>
  <div>
    <!--    Note Modal-->
    <b-modal
      id="note_update_modal"
      ref="note_update_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      size="lg"
      title="Özel Not Düzenle"
      @hide="close_note_update_modal"
    >
      <b-row class="mb-5">
        <quill-editor
          v-model="temp_note"
          :options="snowOption"
        />
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          variant="primary"
          class="mt-1"
          size="sm"
          @click="update_note_button"
        >
          <span class="align-middle"><feather-icon icon="SaveIcon" class="mr-1"/> Kaydet</span>
        </b-button>
      </b-row>
    </b-modal>
    <!--    Note Modal-->

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Kayıt Bulunamadı!
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'customers-list'}"
        >
          Müşteri Listesine geri dön.
        </b-link>
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          lg="3"
        >
          <b-row>
            <b-col cols="12">
              <b-card no-body>
                <b-card-header class="p-25 bg-primary text-center text-white justify-content-center">
                  <div>{{ userData.company_name }}</div>
                </b-card-header>
                <b-card-body class="p-25">
                  <table class="w-100">
                    <tr>
                      <th>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        ><feather-icon
                          icon="UserIcon"
                          class="mr-25"
                        />Yetkili Adı:</span>
                      </th>
                      <td>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        >{{ userData.company_authorized_person_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        ><feather-icon
                          icon="PhoneIcon"
                          class="mr-25"
                        />Yetkili Telefon:</span>
                      </th>
                      <td>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        >{{ userData.company_authorized_person_phone_number }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        ><feather-icon
                          icon="MailIcon"
                          class="mr-25"
                        />Yetkili E-posta:</span>
                      </th>
                      <td>
                        <span
                          style="font-size: 12px"
                          class="font-weight-bold"
                        >{{ userData.company_authorized_person_email }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Vergi No:</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ userData.company_tax_no }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="FlagIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Ülke</span>
                      </th>
                      <td class="pb-50">
                        {{ userData.country.name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="MapPinIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Adres</span>
                      </th>
                      <td class="pb-50">
                        {{ userData.company_address }}
                      </td>
                    </tr>
                  </table>
                </b-card-body>
              </b-card> </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card no-body>
                <b-card-header class="py-25 pl-25 px-0 bg-warning w-100">
                  <b-row class="justify-content-between w-100">
                    <b-col cols="6" class="d-flex text-nowrap text-white align-content-center align-items-center">
                      <feather-icon class="mr-25 text-white" icon="AlertTriangleIcon"/>
                      Özel Not
                    </b-col>
                    <b-col class="text-right text-light-primary" cols="6" @click="show_note_update_modal()">
                      <feather-icon icon="Edit3Icon" /> Düzenle
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <div
                    style="min-height: 200px;"
                    v-html="userData.note"
                  />
                </b-card-body>
              </b-card> </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          lg="9"
        >
          <b-tabs>
            <b-tab>
              <template #title>
                <feather-icon icon="UsersIcon" />
                <span>Kullancılar</span>
                <b-badge
                  class="ml-25"
                  pill
                  variant="light-primary"
                >
                  {{ userData.total_users_count }}
                </b-badge>
              </template>
              <customer-user-list-cart :customer_id="userData.id" />
            </b-tab>
            <b-tab active>
              <template #title>
                <feather-icon icon="MapPinIcon" />
                <span>Merkezler</span>
                <b-badge
                  class="ml-25"
                  pill
                  variant="light-primary"
                >
                  {{ userData.total_centers_count }}
                </b-badge>
              </template>
              <customer-center-list-cart :customer_id="userData.id" />
            </b-tab>
            <!--            <b-tab>-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="KeyIcon" />-->
            <!--                <span>Lisans Yönetimi</span>-->
            <!--              </template>-->

            <!--              <b-card-text>-->
            <!--                Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.-->
            <!--              </b-card-text>-->
            <!--              <b-card-text>-->
            <!--                Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.-->
            <!--              </b-card-text>-->
            <!--            </b-tab>-->
            <!--            <b-tab disabled>-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="EyeOffIcon" />-->
            <!--                <span>Disabled</span>-->
            <!--              </template>-->

            <!--              <b-card-text>-->
            <!--                Halvah dessert fruitcake toffee oat cake tart oat cake topping jelly beans. Pudding sweet pie pastry lemon drops jujubes danish pie gingerbread. Liquorice powder wafer.-->
            <!--              </b-card-text>-->
            <!--            </b-tab>-->
          </b-tabs>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BCard, BModal, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTabs, BTab,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardHeader, BCardBody, VBModal, BCardText, BFormGroup, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Multiselect from 'vue-multiselect'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { ref } from '@vue/composition-api'
import router from '@/router'
import CustomerUserListCart from '@/views/custom_app/admin/components/customer/CustomerUserListCart'
import CustomerCenterListCart from '@/views/custom_app/components/plants/CustomerCenterListCart.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BImg,
    Locale,
    BModal,
    BAlert,
    BTabs,
    BTab,
    BCardHeader,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    flatPickr,
    DateRangePicker,
    vSelect,
    Multiselect,
    VueTelInput,
    CustomerUserListCart,
    quillEditor,
    CustomerCenterListCart,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      temp_note: null,
      userData: null,
    }
  },
  watch: {
  },
  created() {
    useJwt.getCustomerDetail(router.currentRoute.params.id)
      .then(response => {
        this.userData = response.data
      })
      .catch(error => {
        this.userData = undefined
        console.log(error.response)
      })
  },
  methods: {
    show_note_update_modal() {
      this.temp_note = this.userData.note
      this.$refs.note_update_modal.show()
    },
    update_note_button() {
      const formData = new FormData()
      formData.append('note', this.temp_note)
      useJwt.updateCustomer(router.currentRoute.params.id, formData)
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          this.userData = undefined
          console.log(error.response)
        })
      this.$refs.note_update_modal.hide()
    },
    close_note_update_modal() {
      this.$refs.note_update_modal.hide()
    },
  },
  setup() {

  },
}
</script>
<style lang="scss">
.ql-container{
  min-height: 300px;
}
</style>
