<template>
  <div>
    <!--    Cender Devices Modal-->
    <b-modal
      id="devices_modal_customercenterlistcart"
      ref="devices_modal_customercenterlistcart"
      centered
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      size="xl"
      @hide="close_devices_modal_customercenterlistcart"
    >
      <customer-center-device-list-cart
        :center_id="selected_row_id"
        :customer_id="customer_id"
      />
    </b-modal>
    <!--    Cender Devices Modal-->

    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal_customercenterlistcart"
      ref="new_record_modal_customercenterlistcart"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Yeni Kayıt Ekle"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal_customercenterlistcart"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Merkez Adı"
              label-for="center_name"
            >
              <b-form-input
                v-model="center_name"
                placeholder="Merkez Adı"
                name="center_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Açıklama"
              label-for="note"
            >
              <b-form-input
                v-model="note"
                placeholder="Açıklama"
                name="note"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Ülke"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="countries"
                :options="countries_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="İl"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="states"
                :options="states_options"
                label="title"
                :clearable="false"
                :disabled="countries.value!=null ? false : true"

              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="İlçe"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="cities"
                :options="cities_options"
                label="title"
                :clearable="false"
                :disabled="states.value!=null ? false : true"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kurulu Güç"
                label-for="note"
            >
              <b-input-group
                  append="mWh"
              >
                <b-form-input
                    v-model="installed_power"
                    placeholder="Kurulu Güç"
                    name="note"
                    type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Para Birimi"
                label-for="i-center_type"
            >
              <v-select
                  id="i-center_type"
                  v-model="currencies"
                  :options="currencies_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Alış Fiyatı"
                label-for="center_name"
            >
              <b-form-input
                  v-model="purchase_price"
                  placeholder="Alış Fiyatı"
                  name="center_name"
                  type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Satış Fiyatı"
                label-for="center_name"
            >
              <b-form-input
                  v-model="sale_price"
                  placeholder="Satış Fiyatı"
                  name="center_name"
                  type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-1"
            @click="new_record_add_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Ekle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal_customercenterlistcart"
      ref="update_record_modal_customercenterlistcart"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Düzenle"
      footer-class="d-none"
      size="lg"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Merkez Adı"
              label-for="center_name"
            >
              <b-form-input
                v-model="edit_center_name"
                placeholder="Merkez Adı"
                name="center_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Açıklama"
              label-for="note"
            >
              <b-form-input
                v-model="edit_note"
                placeholder="Açıklama"
                name="note"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1" size="sm"
            @click="record_edit_button()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal_customercenterlistcart"
      ref="delete_record_modal_customercenterlistcart"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="lg"
      @hide="close_delete_record_modal_customercenterlistcart"
    >
      <div>

        <b-card-text class="text-center">
          <span class="text-danger">{{ selected_row_name }}</span><span class="mx-1">isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1" size="sm"
            @click="close_delete_record_modal_customercenterlistcart()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-danger"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <b-card no-body>
      <b-row class="justify-content-end m-50">

        <!-- Search -->
        <b-col
            cols="12"
            md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
                size="sm"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Ara..."
            />
            <b-button
                v-b-modal.new_record_modal_customercenterlistcart
                variant="primary"
                size="sm"
                v-if="$can('manage', 'admin')"
            >
              <span class="text-nowrap">Yeni Ekle</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <plants-design :plants="table_data" />
    <div class="mx-2 mb-2" v-if="perPage<totalRows">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        </b-col>
        <!-- Pagination -->
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-tel-input/dist/vue-tel-input.css'
import { VueTelInput } from 'vue-tel-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerCenterDeviceListCart from '@/views/custom_app/admin/components/customer/CustomerCenterDeviceListCart.vue'
import PlantsDesign from "@/views/custom_app/components/plants/PlantsDesign.vue"
export default {
  components: {
    PlantsDesign,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueTelInput,
    CustomerCenterDeviceListCart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user_data: localStorage.getItem('userData'),
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: [],
      tableColumns: [
        { key: 'center_name', label: 'Merkez Adı', sortable: true },
        { key: 'cencer_comment', label: 'Açıklama', sortable: true },
        { key: 'total_devices_count', label: 'Cihaz Sayısı', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      center_name: null,
      note: null,
      edit_center_name: null,
      edit_note: null,
      countries: { title: 'Ülke', value: null },
      countries_options: [],
      states: { title: 'İl Seçin', value: null },
      states_options: [],
      cities: { title: 'İlçe Seçin', value: null },
      cities_options: [],
      installed_power: 0,
      purchase_price: 0,
      sale_price: 0,
      currencies: { title: 'Seçin', value: null },
      currencies_options: [],

    }
  },
  computed: { },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    countries: { handler() { this.getStatesData() } },
    states: { handler() { this.getCitiesData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
    this.getCountriesData()
    this.getCurrenciesData()
  },
  setup() {

  },
  methods: {
    getCurrenciesData() {
      useJwt.getCurrenciesList({
        page: 1,
        page_size: 50000,
      })
          .then(response => {
            this.currencies = { title: 'Seçin', value: null }
            response.data.results.forEach(data => {
              this.currencies_options.push({
                title: data.name,
                value: data.id,
              })
            })
          })
          .catch(error => {
            console.log(error.response)
          })
    },
    getCountriesData() {
      useJwt.getCountries({
        page: 1,
        page_size: 50000,
      })
        .then(response => {
          this.cities = { title: 'İlçe Seçin', value: null }
          this.states = { title: 'İl Seçin', value: null }
          this.countries = { title: 'Ülke', value: null }
          response.data.results.forEach(data => {
            this.countries_options.push({
              title: data.native,
              value: data.id,
            })
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getStatesData() {
      if (this.countries.value) {
        this.states_options = []
        useJwt.getStates({
          page: 1,
          page_size: 50000,
          country: this.countries.value,
        })
          .then(response => {
            this.states = { title: 'İl Seçin', value: null }
            this.cities = { title: 'İlçe Seçin', value: null }
            response.data.results.forEach(data => {
              this.states_options.push({
                title: data.name,
                value: data.id,
              })
            })
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    },
    getCitiesData() {
      if (this.states.value) {
        this.cities_options = []
        useJwt.getCities({
          page: 1,
          page_size: 50000,
          state: this.states.value,
        })
          .then(response => {
            response.data.results.forEach(data => {
              this.cities_options.push({
                title: data.name,
                value: data.id,
              })
            })
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    },
    getTableData() {
      useJwt.getCustomerCenterList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        company: this.customer_id,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.customerCenterDetail(row_id)
        .then(response => {
          this.edit_center_name = response.data.center_name
          this.edit_note = response.data.note
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal_customercenterlistcart.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.customerCenterDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.center_name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal_customercenterlistcart.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('company', this.customer_id)
      formData.append('center_name', this.center_name)
      formData.append('cencer_comment', this.note)
      formData.append('country', this.countries.value)
      formData.append('states', this.states.value)
      formData.append('cities', this.cities.value)
      formData.append('currencies', this.currencies.value)
      formData.append('purchase_price', this.purchase_price)
      formData.append('sale_price', this.sale_price)
      formData.append('installed_power', this.installed_power)

      useJwt.createCustomerCenter(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal_customercenterlistcart()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Hata',
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('center_name', this.edit_center_name)
      formData.append('note', this.edit_note)
      useJwt.updateCustomerCenter(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.customerCenterDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal_customercenterlistcart()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal_customercenterlistcart() {
      this.$refs.new_record_modal_customercenterlistcart.hide()
      this.center_name = null
      this.note = null
      this.installed_power = 0
      this.purchase_price = 0
      this.sale_price = 0
      this.getCurrenciesData()
      this.getCountriesData()
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal_customercenterlistcart.hide()
      this.edit_center_name = null
      this.edit_note = null
      this.selected_row_id = null
    },
    close_delete_record_modal_customercenterlistcart() {
      this.$refs.delete_record_modal_customercenterlistcart.hide()
      this.selected_row_id = null
    },
    show_devices_modal_customercenterlistcart(row_id) {
      this.selected_row_id = row_id
      this.$refs.devices_modal_customercenterlistcart.show()
    },
    close_devices_modal_customercenterlistcart() {
      this.$refs.devices_modal_customercenterlistcart.hide()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
